import React from "react"
import Bio from "./bio"

const Footer = () => {
  return (
    <footer>
      <Bio />
      <small>
        <p>
          This site is possible only with the beautiful work of{" "}
          <a href="https://www.gatsbyjs.org">Gatsby</a> and{" "}
          <a
            href="https://github.com/renyuanz/leonids"
            target="_blank"
            rel="noreferrer"
          >
            Leonids theme
          </a>
          .
        </p>
      </small>
    </footer>
  )
}

export default Footer
