/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Image from "gatsby-image"

import { rhythm } from "../utils/typography"
import { graphql, useStaticQuery } from "gatsby"
import SelfPlug from "./SelfPlug"

const AuthorBlock = ({ name, avatar, summary, action }) => (
  <div
    style={{
      display: `flex`,
      alignItems: "center",
      // marginBottom: rhythm(2.5),
    }}
  >
    <Image
      fixed={avatar}
      alt={name}
      style={{
        marginRight: rhythm(1 / 2),
        marginBottom: 0,
        minWidth: 50,
        borderRadius: `100%`,
      }}
      imgStyle={{
        borderRadius: `50%`,
      }}
    />
    <div>
      {action} by <strong>{name}</strong>
      {summary ? `, ${summary}` : ""}
    </div>
  </div>
)

const getAvatarFixed = gatsbyImgObj =>
  (gatsbyImgObj &&
    gatsbyImgObj.childImageSharp &&
    gatsbyImgObj.childImageSharp.fixed) ||
  null

const Bio = () => {
  const data = useStaticQuery(
    graphql`
      query {
        writer_avatar: file(
          childImageSharp: { fixed: { originalName: { eq: "hulloanson.png" } } }
        ) {
          childImageSharp {
            fixed(height: 50, width: 50) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        site {
          siteMetadata {
            author {
              name
              summary
            }
            social {
              mastodon
              email
              telegram
            }
          }
        }
      }
    `
  )
  const {
    writer_avatar = null,
    site: {
      siteMetadata: {
        author: { name, summary },
        social: { mastodon, email, telegram },
      },
    },
  } = data
  return (
    <div
      style={{
        // display: `flex`,
        marginBottom: rhythm(2.5),
      }}
    >
      <div>
        <AuthorBlock
          name={name}
          summary={summary}
          avatar={getAvatarFixed(writer_avatar)}
          action="Written"
        />
        <div style={{ marginBottom: rhythm(0.5) }} />
        <div>
          <small>
            <SelfPlug />
          </small>
          <small>Want to start a passionate discussion with me?</small>
          <br></br>
          <p>
            <small>
              You can find me on the{" "}
              <a rel="me" href={mastodon}>
                Fediverse
              </a>
              , through <a href={`mailto:${email}`}>email</a> or{" "}
              <a href={`https://t.me/${telegram}`}>Telegram</a>
            </small>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Bio
