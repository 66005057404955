import React from 'react'

import { styled } from '@material-ui/styles'

const styles = {
    '& > .content': {
        position: 'relative',
        '& > .mask': {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: '#000',
            zIndex: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
                background: '#333',
            },
        }
    }
}

class Answer extends React.Component {
    constructor(props) {
        super(props)
        this.state = { showAnswer: !!props.show}
    }
    showAnswer(e) {
        e.preventDefault()
        this.setState({
            showAnswer: true,
        })
    }
    hideAnswer(e) {
        e.preventDefault()
        this.setState({
            showAnswer: false,
        })
    }
    render() {
        return <div {...this.props}>
            <p>
                {/* Answer:{' '} */}
                {this.state.showAnswer && <a href='#hide-answer' onClick={this.hideAnswer.bind(this)}>(hide)</a>}
            </p>
            <div className='content'>
                <a href='#show-answer' className='mask'
                    style={this.state.showAnswer ? {display: 'none'} : {}}
                    onClick={this.showAnswer.bind(this)}
                >Click to show answer</a>
                {this.props.children}
            </div>
        </div>

    }
}

export default styled(Answer)(styles)
