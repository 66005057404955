/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

const SelfPlug = () => {
  const resumeLink = "/anson_chung_lun_yuen_resume.pdf"
  return (
    <p style={{ margin: "1rem 0" }}>
      Open to work in Canada! <a href={resumeLink}>Click to see my resume.</a>
    </p>
  )
}

export default SelfPlug
